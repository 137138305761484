const animation = () => {
    const fadeInObjects = document.querySelectorAll('.u-scrollAnimationFadeIn')
    const fadeUpObjects = document.querySelectorAll('.u-scrollAnimationFadeUp')
    const slideInObjects = document.querySelectorAll('.u-scrollAnimationSlideIn')
    const zoomInObjects = document.querySelectorAll('.u-scrollAnimationZoomIn')
    const slideExpandObjects = document.querySelectorAll('.u-scrollAnimationSlideExpand')
    const popUpObjects = document.querySelectorAll('.u-scrollAnimationPopUp')
    // オプション
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    }
    const animationObserver = new IntersectionObserver(function (entries) {
      for (let i = 0, len = entries.length; i < len; i++) {
        // スクロール検知
        if (entries[i].isIntersecting) {
          if (entries[i].target.classList.contains('u-scrollAnimationFadeIn')) {
            entries[i].target.style.animation = 'fadeIn 3s cubic-bezier(0.33, 1, 0.68, 1) forwards'
          }
          if (entries[i].target.classList.contains('u-scrollAnimationFadeUp')) {
            entries[i].target.style.animation = 'fadeUp 3s cubic-bezier(0.33, 1, 0.68, 1) forwards'
          }
          if (entries[i].target.classList.contains('u-scrollAnimationSlideIn')) {
            entries[i].target.style.animation = 'slideIn 1s cubic-bezier(0.25, 1, 0.5, 1) forwards'
          }
          if (entries[i].target.classList.contains('u-scrollAnimationZoomIn')) {
            entries[i].target.style.animation = 'zoomIn 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards'
          }
          if (entries[i].target.classList.contains('u-scrollAnimationSlideExpand')) {
            entries[i].target.style.setProperty('--slide-expand', 'slideExpand 4s cubic-bezier(0.22, 1, 0.36, 1) forwards')
          }
          if (entries[i].target.classList.contains('u-scrollAnimationPopUp')) {
            entries[i].target.style.animation = 'popUp 0.6s cubic-bezier(0.22, 1, 0.36, 1) forwards'
          }
        } else {
          // TODO: テストが終了したらelse処理は削除
          entries[i].target.style.animation = ''
          entries[i].target.style.setProperty('--slide-expand', '')
        }
      }
    }, options)
    if (fadeInObjects) {
      for (let i = 0, len = fadeInObjects.length; i < len; i++) {
        animationObserver.observe(fadeInObjects[i])
      }
    }
    if (fadeUpObjects) {
      for (let i = 0, len = fadeUpObjects.length; i < len; i++) {
        animationObserver.observe(fadeUpObjects[i])
      }
    }
    if (slideInObjects) {
      for (let i = 0, len = slideInObjects.length; i < len; i++) {
        animationObserver.observe(slideInObjects[i])
      }
    }
    if (zoomInObjects) {
      for (let i = 0, len = zoomInObjects.length; i < len; i++) {
        animationObserver.observe(zoomInObjects[i])
      }
    }
    if (slideExpandObjects) {
      for (let i = 0, len = slideExpandObjects.length; i < len; i++) {
        animationObserver.observe(slideExpandObjects[i])
      }
    }
    if (popUpObjects) {
      for (let i = 0, len = popUpObjects.length; i < len; i++) {
        animationObserver.observe(popUpObjects[i])
      }
    }
}
export default animation
