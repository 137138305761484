import React from 'react'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import './src/styles/layout.scss'
import detailsSummaryAccordionAnim from './src/scripts/details-summary-accordion'
import animation from './src/scripts/animation'

export const onRouteUpdate = ({ location }) => {
  animation()
  // '/aim'でスタートするページのアコーディオン処理
  if (location.pathname.startsWith('/aim')) {
    detailsSummaryAccordionAnim()
  }

  // ページ遷移後のスクロール位置をトップにする
  window.scrollTo(0, 0)

  // ハッシュリンクのスクロール処理
  if (location.hash) {
    setTimeout(() => {
      const element = document.querySelector(location.hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 500) // ページ遷移後のコンテンツ読み込みを考慮して少し長めの待機時間を設定
  }
}

export const wrapRootElement = ({ element }) => <ContentfulLivePreviewProvider locale="ja-JP">{element}</ContentfulLivePreviewProvider>

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// export const onInitialClientRender = () => {
//     setTimeout(function() {
//         document.getElementById("___loader").style.display = "none"
//     }, 400)
// }
// const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
//   }

//   export const onClientEntry = () => {
//     window.onload = () => {
//       addScript("https://fast.wistia.com/embed/medias/9rvl8vgrzg.jsonp")
//       addScript("https://fast.wistia.com/assets/external/E-v1.js")
//     }
//   }
